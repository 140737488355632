import i18n from 'i18next';
import {
  AREA_MAX_LENGTH,
  BRANCH_NAME_MAX_LENGTH,
  COMPANY_NAME_MAX_LENGTH,
  COMPANY_PERSON_MAX_LENGTH,
  CUSTOMER_NAME_MAX_LENGTH,
  DESCRIPTION_MAX_LENGTH,
  PHONE_NUMBER_MAX_LENGTH,
} from "./appConstants";

export const Message = {
  //email
  email: i18n.t("errorMessages.emailRequired"),
  invalidEmail: i18n.t("errorMessages.invalidEmail"),

  //password
  oldPassword: i18n.t("errorMessages.oldPassword"),
  password: i18n.t("errorMessages.password"),
  newPassword: i18n.t("errorMessages.newPassword"),
  invalidPassword: i18n.t("errorMessages.invalidPassword"),
  invalidNewPassword: i18n.t("errorMessages.invalidNewPassword"),
  passwordInvalid: i18n.t("errorMessages.passwordInvalid"),
  confirmPassword: i18n.t("errorMessages.confirmPassword"),
  passwordNotMatch: i18n.t("errorMessages.passwordNotMatch"),
  newPasswordNotMatch: i18n.t("errorMessages.newPasswordNotMatch"),
  confirmNewPassword: i18n.t("errorMessages.confirmNewPassword"),

  //company name
  companyName: i18n.t("errorMessages.companyName"),
  companyNameMaxLength: i18n.t("errorMessages.companyNameMaxLength"),

  //customer name
  customerName: i18n.t("errorMessages.customerName"),
  customerNameMaxLength: i18n.t("errorMessages.customerNameMaxLength"),

  //contact name
  contactName: i18n.t("errorMessages.contactName"),
  contactNameMaxLength: i18n.t("errorMessages.contactNameMaxLength"),

  //Branch Name
  branchName: i18n.t("errorMessages.branchName"),
  branchNameMaxLength: i18n.t("errorMessages.branchNameMaxLength"),

  //phone number
  phoneNumber: i18n.t("errorMessages.phoneNumber"),
  phoneNumberMaxLength: i18n.t("errorMessages.phoneNumberMaxLength"),

  //Description
  description: i18n.t("errorMessages.description"),
  descriptionMaxLength: i18n.t("errorMessages.descriptionMaxLength"),
  descriptionSpace : i18n.t("errorMessages.descriptionNoSpace"),
  descriptionLimit: i18n.t("errorMessages.descriptionLimit"),

  //fil
  fileNumber: i18n.t("errorMessages.fileNumber"),
  fileSize: i18n.t("errorMessages.fileSize"),
  fileSizeLimit: i18n.t("errorMessages.fileSizeLimit"),
  filePDFSizeLimit: i18n.t("errorMessages.filePDFSizeLimit"),
  fileType: i18n.t("errorMessages.fileType"),

  //industry
  IndustrySelectRequired: i18n.t("errorMessages.IndustrySelectRequired"),
  CustomerSelectRequired: i18n.t("errorMessages.CustomerSelectRequired"),

  // Country
  CountrySelectRequired: i18n.t("errorMessages.CountrySelectRequired"),

  // City
  CitySelectRequired: i18n.t("errorMessages.CitySelectRequired"),
  Location: i18n.t("errorMessages.Location"),
  //Area
  areaRequired: i18n.t("errorMessages.areaRequired"),
  areaMaxLength: i18n.t("errorMessages.areaMaxLength"),

  checkbox: i18n.t("errorMessages.checkbox"),

  //RFP
  guarantee: i18n.t("errorMessages.guarantee"),
  fileCount: i18n.t("errorMessages.fileCount"),
  startDate: i18n.t("errorMessages.startDate"),
  endDate: i18n.t("errorMessages.endDate"),
  rfpTilte: i18n.t("errorMessages.rfpTilte"),
  endDateValid: i18n.t("errorMessages.endDateValid"),
  priceValid: i18n.t("errorMessages.priceValid"),
  questionRequired: i18n.t("errorMessages.requiredQuestion"),
  priceRequired : i18n.t("errorMessages.priceRequired"),
};
