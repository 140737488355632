/*
 * File: ExpressDetails.js
 * Author: Harsh Chauhan
 * Created Date: January 13th, 2023
 * Last Modified Date: March 20th, 2024
 * Description: It is responsible for rendering a page that displays details of an express service.
 */
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";

//* Multilangual
import { useTranslation } from "react-i18next";

//* page scss
import "../Service/Service.scss";
import "./Express.scss";
import { Container, Form, Label, Input, Button, Spinner } from "reactstrap";
import { ChevronRight } from "react-feather";
import { BranchNameRules } from "../../constants/rules";
import { toast } from "react-toastify";
import FormSelectInput from "../../component/Form/FormSelectInput";
import { useForm } from "react-hook-form";
import { CameraIcon } from "../../assets/images/svg";
import DummyCircle from "../../assets/images/icon/user.png";
import { IoMdClose } from "react-icons/io";
import {
  ACCEPT_DOC,
  ACCEPT_IMAGE_AND_VIDEO,
  FILE_SIZE_LIMIT,
  INDIVIDUAL_FILE_SIZE_LIMIT,
  TOAST_AUTO_CLOSE,
} from "../../constants/appConstants";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  getBranchNameApi,
  getProviderListApi,
  getSingleProviderApi,
  getProblemLimitationData,
} from "../../redux/actions/service/serviceAction";
import { sendExpressProviderRequest } from "../../redux/actions/express/expressAction";
import { useDispatch } from "react-redux";
import ProviderModal from "../../component/modal/service/ProviderModal";
import OrderSummary from "../../component/modal/service/OrderSummary";
import SuccessModal from "../../component/modal/service/SuccessModal";
import ServiceLeftShimmer from "../../component/shimmer/serviceLeftShimmer";
import Footer from "../../component/common/Footer";
import TextEditor from "../../component/common/TextEditor";
import { checkVideoLength } from "../../constants/utils";
import NewOrderAddress from "../../component/common/NewOrderAddress"
import { FiPlus } from "react-icons/fi"
import phone from "phone"

const ExpressDetails = () => {
  //* Multilangual
  const { t } = useTranslation();

  //* State variables
  const [isLoading, setLoading] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [providerModal, setProviderModal] = useState(false);
  const [singleProvider, setSingleProvider] = useState(null);
  const [successModal, setSuccessModal] = useState(false);
  
  //* Initializing the dispatch function from Redux
  const dispatch = useDispatch();

  //* Hook for navigation
  const navigate = useNavigate();
  
  //* Initializing react-hook-form
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      isSave: false,
    },
    reValidateMode: "onChange"
  })

  const [branchData, setBranchData] = useState([]);
  const [providerList, setProviderList] = useState([]);
  const [providerDetails, setProviderDetails] = useState([]);
  const [dataValue, setDataValue] = useState([]);

   //* Get search parameters from URL
  const [searchParams] = useSearchParams();
  
  const [attachDoc, setAttachDoc] = useState([]);
  const categoryId = searchParams.get("categoryId");
  const subcategoryId = searchParams.get("subcategoryId");
  const [showBranchValue, setBranchValue] = useState({});
  const [problemLimits, setProblemLimits] = useState({});
  const [fullCustomerAddress, setFullCustomerAddress] = useState();
  const addNewAddress = watch('branches') === 'new'
  const mobileNumber = watch("phoneNo");

  /*
  * useEffect is used here to update the value of 'branches' in response to changes in 'showBranchValue'.
  * It sets the value of 'branches' to the ID of 'showBranchValue' and resets 'fullCustomerAddress' to an empty object.
  */
  useEffect(() => {
    setValue('branches', showBranchValue?.id)
    setFullCustomerAddress({})
  }, [showBranchValue])
  
   //* Function for manage selected categories
  const getBranchDropdown = async () => {
    setLoading(true);
    const res = await dispatch(getBranchNameApi());
    if (!res.data.error) {
      setBranchData([
        ...res.data.data,
        { branchName: t("newOrderAddress.addAddressOpt"), id: 'new', icon: FiPlus }
      ])
      setBranchValue(res?.data?.data[0])
    }
    setLoading(false);
  };

   //* Function for get provider list
  const getProblemLimitation = async () => {
    setLoading(true);
    try {
      const res = await dispatch(getProblemLimitationData());
      if (!res.data.error) {
        const problemLimits = res.data.data.reduce((acc, item) => {
          acc[item.key] = parseInt(item.value, 10);
          return acc;
        }, {});
        setProblemLimits(problemLimits);
      }
    } catch (error) {
      console.error("Error fetching problem limitations:", error);
    } finally {
      setLoading(false);
    }
  };

/**
 * Retrieves the provider list data from the API based on the given category and subcategory IDs.
 * @return {Promise<void>} - A promise that resolves when the provider list data is successfully retrieved and set.
 */
  const getProviderListData = async () => {
    setLoading(true);
    const res = await dispatch(
      getProviderListApi({
        categoryId: categoryId,
        subcategoryId: subcategoryId,
      })
    );
    if (!res.data.error) {
      setProviderList({ ...res.data.data });
    }
    setLoading(false);
  };

  useEffect(() => {
    getProblemLimitation();
    getBranchDropdown();
    getProviderListData();
  }, []);

  /**
   * Function is used for hire service provider click management
   * @param {*} item
   */
  const onProviderModal = (item) => {
    setDataValue(item.id);
    setProviderModal(!providerModal);
    getSingleData(item.id);
  };

  /**
   * Function is used for get particular provider data after hired
   * @param {*} id
   */
  const getSingleData = async (id) => {
    setLoading(true);
    const res = await dispatch(getSingleProviderApi(id));
    if (!res.data.error) {
      setProviderDetails({ ...res.data.data });
    }
    setLoading(false);
  };

  /**
   * Function for upload Attachment and Manage
   * @param {obj} data
   */
  const onAttachChange = async (e) => {
    const file = e.target.files[0];
    const totalImages = problemLimits.noOfGalleryPhotos;
    const totalVideos = problemLimits.noOfGalleryVideos;
    const totalFiles = totalImages + totalVideos;
    const data = [...attachDoc];

    if (
      !ACCEPT_IMAGE_AND_VIDEO.includes(file.type) &&
      !ACCEPT_DOC.includes(file.type)
    ) {
      toast.error(t("errorMessages.fileMediaType"), {
        autoClose: TOAST_AUTO_CLOSE,
      });
      e.target.value = "";
      return;
    }

    if (file.type === "video/mp4") {
      if (Math.round(file.size) > FILE_SIZE_LIMIT) {
        toast.error(t("errorMessages.fileSize"), {
          autoClose: TOAST_AUTO_CLOSE,
        });
        e.target.value = "";
        return;
      } else {
        //* check if video duration is more than allowed value
        const res = await checkVideoLength(file, problemLimits?.durationOfGalleryVideo,);
        if (res) {
          e.target.value = null;
          toast.error(
            t("errorMessages.videoDuration", {
              duration: problemLimits?.durationOfGalleryVideo,
            }),
            {
              autoClose: TOAST_AUTO_CLOSE,
            }
          );
        }
      }
    }

    if (
      file.type !== "video/mp4" &&
      Math.round(file.size / 1024) > INDIVIDUAL_FILE_SIZE_LIMIT
    ) {
      if (file.type === "application/pdf") {
        toast.error(t("errorMessages.filePDFSizeLimit"), {
          autoClose: TOAST_AUTO_CLOSE,
        });
        e.target.value = "";
        return;
      } else {
        toast.error(t("errorMessages.fileSizeLimit"), {
          autoClose: TOAST_AUTO_CLOSE,
        });
        e.target.value = "";
        return;
      }
    }
    if (file.type === "video/mp4") {
      if (
        data.filter((item) => item.type === "video/mp4").length >= totalVideos
      ) {
        toast.error(t("errorMessages.totalVideos", { number: totalVideos }), {
          autoClose: TOAST_AUTO_CLOSE,
        });
        e.target.value = "";
        return;
      }
    }

    if (
      file.type.startsWith("image/png") ||
      file.type.startsWith("image/jpeg")
    ) {
      if (
        data.filter((item) => item.type.startsWith("image/")).length >=
        totalImages
      ) {
        toast.error(t("errorMessages.totalImages", { number: totalImages }), {
          autoClose: TOAST_AUTO_CLOSE,
        });
        e.target.value = "";
        return;
      }
    }
    //* 2 is added for doc type limitation. need to update once added from admin
    if (data.length + e.target.files.length > totalFiles + 2) {
      toast.error(t("errorMessages.totalFiles"), {
        autoClose: TOAST_AUTO_CLOSE,
      });
      e.target.value = "";
      return;
    }
    for (let i = 0; i < e.target.files.length; i++) {
      if (e.target.files[i].size > FILE_SIZE_LIMIT) {
        toast.error(t("errorMessages.fileSize"), {
          autoClose: TOAST_AUTO_CLOSE,
        });
        e.target.value = "";
        return;
      }
    }

    for (let i = 0; i < e.target.files.length; i++) {
      data.push(e.target.files[i]);
    }

    setAttachDoc(data);
    e.target.value = "";
  };

  /**
   * Function for Delete Attachments
   * @param {*} i
   */
  const deleteAttachments = (i) => {
    let oldArray = [...attachDoc];
    oldArray.splice(i, 1);
    setAttachDoc(oldArray);
  };

  /**
   * Managenent of service
   * @param {*} data
   */
  const onFormSubmit = async (data) => {
    if (
      data?.description.description.length > problemLimits.noOfCharactersPerText
    ) {
      toast.error(
        t("errorMessages.descriptionMaxLength", {
          number: problemLimits.noOfCharactersPerText,
        }),
        {
          autoClose: TOAST_AUTO_CLOSE,
        }
      );
      return;
    }
    setShowLoader(true);
    const finalPhoneValue = phone(`+${mobileNumber}`);
    const branchFinalData = branchData.find((b) => b.id === data.branches && b.id !== 'new');
    const formData = new FormData();

    formData.append("category", categoryId);
    formData.append("subcategory", subcategoryId);
    formData.append(
      "branchName",
      !data?.isSave ? (branchFinalData?.branchName || branchFinalData?.name || '') : data?.branchName)
    formData.append("latitude", fullCustomerAddress?.lat || branchFinalData?.latitude)
    formData.append("longitude", fullCustomerAddress?.long || branchFinalData?.longitude)
    formData.append("country", fullCustomerAddress?.country || branchFinalData?.country)
    formData.append("city", fullCustomerAddress?.city || branchFinalData?.city)
    formData.append("area", fullCustomerAddress?.placeName || branchFinalData?.area)
    formData.append("isSave", data?.isSave)
    formData.append("name", data?.name)
    formData.append("email", data.email || '')
    formData.append("countryCode", finalPhoneValue?.countryCode || '')
    formData.append("phoneNo", finalPhoneValue?.phoneNumber?.replace(finalPhoneValue.countryCode, "") || '')
    formData.append(
      "formattedDescription",
      data?.description.formattedDescription
    );
    formData.append("description", data?.description?.description);
    formData.append("isAttachments", attachDoc && attachDoc.length > 0)
    if (attachDoc && attachDoc.length > 0) {
      for (let i = 0; i < attachDoc.length; i++) {
        formData.append("attachments", attachDoc[i]);
      }
    }
    const res = await dispatch(sendExpressProviderRequest(formData))

    if (!res.data.error) {
      setShowLoader(false);
      navigate("/my-orders?expressId=1");
    }
    setShowLoader(false);
  };
  return (
    <>
      <Helmet>
        <title>EJAD - Service Details</title>
      </Helmet>
      <div className="home-section common-design-section">
        <div className="common-design-section-data single-data">
          <div className="right-design-data">
            <section className="service-section">
              <Container fluid="lg">
                <div className="service-section-data">
                  {!isLoading ? (
                    <div className="service-details-left-data">
                      <Form
                        className="mb-0"
                        onSubmit={handleSubmit(onFormSubmit)}
                        noValidate
                      >
                        <div className="service-details-header">
                          <div className="head-data">
                            <h3>{t("jobSection.service1")}</h3>
                          </div>
                          <div className="replace-data">
                            <Link to="/express" className="btn-light">
                              {t("serviceDetails.replaceBtnText")}
                              <ChevronRight size={12} />
                            </Link>
                          </div>
                        </div>

                        <div className="service-main-card full mb40">
                          <div
                            className="one"
                            style={{
                              background: `url(${providerList?.category?.icon || DummyCircle
                                })`,
                            }}
                          ></div>
                          <div className="two">
                            <span>{`${providerList?.category?.value} / ${providerList?.subcategory?.value}`}</span>
                          </div>
                        </div>

                        <div className="head-data mb20">
                          <h3>{t("serviceDetails.chooseBranchLabel")}</h3>
                        </div>
                        {branchData && branchData.length >= 0 && (
                          <FormSelectInput
                            fgClass="mb40"
                            multiple={false}
                            control={control}
                            id={`branches`}
                            name={`branches`}
                            label={t("profile.branchData.branchTitle")}
                            placeholder={t("profile.branchData.branchName")}
                            options={branchData}
                            errors={errors}
                            optionValue="id"
                            optionLabel="branchName"
                            isSearchable={false}
                            required={true}
                            rules={BranchNameRules}
                            onChangeCallback={(val) => {
                              setBranchValue(val)
                              setValue('name', '')
                              setValue('phoneNo', '')
                              setValue('email', '')
                              setValue('branchName', '')
                              setValue('isSave', false)
                            }}
                          />
                        )}
                        {
                          addNewAddress ?
                            <NewOrderAddress
                              register={register}
                              control={control}
                              errors={errors}
                              fullCustomerAddress={fullCustomerAddress}
                              setFullCustomerAddress={setFullCustomerAddress}
                              watch={watch}
                              setValue={setValue}
                            />
                            : null
                        }
                        <div className="brief-card">
                          <div className="heading">
                            {t("serviceDetails.briefCard.title")}
                          </div>
                          <TextEditor
                            control={control}
                            setValue={setValue}
                            name={"description"}
                            errors={errors}
                            register={register}
                            placeholder={t("profile.aboutLabel")}
                          />

                          <div className="heading">
                            {t("serviceDetails.briefCard.attachLabel")}
                          </div>
                          <div className="cu-form-group mb-0">
                            {attachDoc && attachDoc.length > 0 && (
                              <ul className="listing-attachments">
                                {attachDoc.map((item, i) => {
                                  return (
                                    <li key={i}>
                                      <div className="list-data">
                                        <div
                                          className="one"
                                          onClick={() => deleteAttachments(i)}
                                        >
                                          <IoMdClose />
                                        </div>
                                        <div className="two">
                                          <p>{item.name}</p>
                                        </div>
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                            )}
                            <Input
                              type="file"
                              hidden
                              id="attach"
                              multiple
                              onChange={onAttachChange}
                              accept={ACCEPT_IMAGE_AND_VIDEO}
                            />
                            <div className="btn-box">
                              <Label
                                for="attach"
                                className="attach-btns with-white"
                              >
                                <CameraIcon />
                                {t("serviceDetails.briefCard.mediaBtnText")}
                              </Label>
                            </div>
                          </div>
                        </div>

                        <Button
                          color="main"
                          className="cu-btn br20"
                          block
                          type="submit"
                          disabled={showLoader}
                        >
                          {showLoader ? (
                            <Spinner size={"sm"} />
                          ) : (
                            t("btnLabel.send")
                          )}
                        </Button>
                      </Form>
                    </div>
                  ) : (
                    <>
                      <div className="service-details-left-data">
                        <ServiceLeftShimmer />
                      </div>
                    </>
                  )}
                  <OrderSummary
                    isLoading={isLoading}
                    data={providerList?.category?.value}
                    dataSubService={providerList?.subcategory?.value}
                    branchName={
                      !addNewAddress ? (showBranchValue?.branchName || showBranchValue?.name) : ''
                    }
                    providerData={singleProvider}
                  />
                </div>
              </Container>
            </section>

            {/* Common Footer */}
            <Footer />
          </div>
        </div>
      </div>

      <ProviderModal
        modal={providerModal}
        toggleModal={() => setProviderModal(!providerModal)}
        isShowProvider={(val) => setSingleProvider(val)}
        isLoading={isLoading}
        dataValue={providerDetails}
        showConatct={true}
      />

      <SuccessModal
        modal={successModal}
        toggleModal={() => setSuccessModal(!successModal)}
        textData={`${t("successModal.success1")} ${t(
          "rfp.serviceProvider"
        )}. ${t("successModal.success2")}`}
      />
    </>
  );
};

export default ExpressDetails;
