/*
 * File: TextEditor.js
 * Author: Niyati Shah
 * Created Date: March 15th, 2024
 * Last Modified Date: March 20th, 2024 
 * Description: This code snippet defines a reusable text editor component with validation and error handling capabilities.
 */
import React, { useState, useEffect } from "react";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML } from "draft-convert";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Controller } from "react-hook-form";
import { getProblemLimitationData } from "../../redux/actions/service/serviceAction";
import { useDispatch } from "react-redux";
import { Message } from "../../constants/messages";
import i18n from "../../i18n/i18n";
import '../../assets/scss/common.scss';
import { toolbarOptions } from "../../constants/appConstants";



function TextEditor({
  name,
  control,
  setValue,
  errors,
  errorMsg,
  placeholder,
}) {

  //* Initializing dispatch function from Redux
  const dispatch = useDispatch();
  //* State variables
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [problemLimits, setProblemLimits] = useState({});

   //* Function to handle editor content change
  const handleChange = (data) => {
    setEditorState(data);
    const formattedDescription = convertToHTML(data.getCurrentContent());
    const description = data.getCurrentContent().getPlainText();
    setValue(name, { description, formattedDescription });
  };

  //* Function to fetch problem limitations
  const getProblemLimitation = async () => {
    try {
      const res = await dispatch(getProblemLimitationData());
      if (!res.data.error) {
        const problemLimits = res.data.data.reduce((acc, item) => {
          acc[item.key] = parseInt(item.value, 10);
          return acc;
        }, {});
        setProblemLimits(problemLimits);
      }
    } catch (error) {
      console.error("Error fetching problem limitations:", error);
    }
  };
  //* Fetch problem limitations on component mount
  useEffect(() => {
    getProblemLimitation();
  }, []);

  //* Function to validate maximum length of the editor content
  const maxLengthValidation = () => {
    const descriptionLimit = problemLimits.noOfCharactersPerText;
    const currentLength = editorState.getCurrentContent().getPlainText().length;
    if (currentLength === 0) {
      return Message.description;
    }
    if (currentLength > descriptionLimit) {
      return i18n.t(Message.descriptionLimit, { descriptionLimit: descriptionLimit });
    }
  };


  return (
    <>
      <div className="text-editor-wrapper">
        <Controller
          control={control}
          name={name}
          rules={{
            validate: {
              required: maxLengthValidation,
              maxLength: maxLengthValidation,
            },
          }}
          render={({ field: { onChange, value, ref, ...field } }) => (
            <div>
              <Editor
                editorState={editorState}
                editorClassName="demo-editor"
                onEditorStateChange={handleChange}
                placeholder={placeholder}
                toolbar={toolbarOptions}
                ref={ref}
                {...field}
              />
            </div>
          )}
        />
      </div>
      <span className="error-msg">
        {errorMsg || errors[name]?.message}
      </span>
    </>
  );
}

export default TextEditor;
